import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import useFetchCommunityData from '../hooks/useFetchCommunityData';
import Spinner from '../components/spinner/Spinner';
import MainContent from '../components/MainContent';
import CommunityPostItem from '../components/community/CommunityPostItem';

function AdminPosts() {
  const { data, isLoading } = useFetchCommunityData(
    ['admin-posts'],
    '/posts/admin',
    {},
    "Couldn't get posts. please try again",
    true
  );

  const queryClient = useQueryClient();

  const [newPosts, setNewPosts] = useState(false);

  const handleNewPosts = () => {
    queryClient.invalidateQueries(['admin-posts']);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setNewPosts(false);
  };

  return (
    <MainContent>
      <div className="mt-24 w-full">
        {newPosts && (
          <div
            onClick={handleNewPosts}
            className=" w-full -mt-2 text-white "
            style={{ zIndex: 100000 }}
          >
            <p className="bg-claret-500 w-fit px-4 py-1 rounded-full mx-auto   z-50">
              New Posts
            </p>
          </div>
        )}
        {isLoading && <Spinner />}
        {!isLoading &&
          data?.data?.posts &&
          data?.data?.posts.map((post) => (
            <CommunityPostItem key={post.id} post={post} />
          ))}
      </div>
    </MainContent>
  );
}

export default AdminPosts;
