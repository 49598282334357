import React from 'react';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import Spinner from './spinner/Spinner';
import TableWrapper from '../utils/TableWrapper';

export default function ZoomSessionRegistrantsRawData({
  zoomSessionID,
  zoomSessionType,
}) {
  const columns = [
    { title: 'Email', field: 'email' },
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row?.firstname}</p>,
    },
    {
      title: 'Middle Name',
      field: 'middlename',
      render: (row) => <p className="capitalize">{row?.middlename || 'N/A'}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row?.lastname}</p>,
    },
    { title: 'Country', field: 'country' },
    { title: 'Phone Number', field: 'phone_number' },
    { title: 'Gender', field: 'gender' },
    { title: 'Academic Level', field: 'level_of_education' },
    { title: 'Cohort', field: 'cohort_name' },
    {
      title: 'Joined In App',
      field: 'joined_in_app',
      render: (row) => <p>{row?.joined_in_app ? 'Yes' : 'No'}</p>,
    },
  ];

  const {
    data: zoomSessionAnalyticsOverviewData,
    isLoading: isLoadingZoomSessionDetails,
  } = useFetchAnalytics(
    ['class-session-analytics-overview', zoomSessionType, zoomSessionID],
    `/class-sessions/${zoomSessionType}/${zoomSessionID}/overview`,
    {},
    `Couldn't fetch ${zoomSessionType} analytics overview. Please try again.`,
    zoomSessionType && zoomSessionID
  );

  const {
    data: classSessionRegistrantsRawData,
    isLoading: isLoadingZoomSessionRegistrants,
  } = useFetchAnalytics(
    ['class-session-registrants-raw-data', zoomSessionType, zoomSessionID],
    `/class-sessions/registrants/${zoomSessionType}/${zoomSessionID}/raw-data`,
    {},
    "Couldn't fetch class session registrants raw data. Please try again.",
    zoomSessionType && zoomSessionID
  );

  return (
    <div className="">
      {isLoadingZoomSessionDetails &&
      isLoadingZoomSessionRegistrants &&
      zoomSessionID ? (
        <div className="flex items-center justify-center bg-white">
          <Spinner />
        </div>
      ) : (
        <TableWrapper
          title={`${
            zoomSessionAnalyticsOverviewData?.data?.title || ''
          } Registrants`}
          columns={columns}
          data={classSessionRegistrantsRawData?.data?.registrants || []}
        />
      )}
    </div>
  );
}
