import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Select from 'react-select';
import Spinner from './spinner/Spinner';
// import useFetchData from '../hooks/useFetchData';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    title: yup.string().required('Please provide a title'),
    startDate: yup
      .date()
      .required('Please enter a valid date')
      .min(new Date(), 'Start date cannot be in the past!'),
    endDate: yup.date().when(
      'startDate',
      // eslint-disable-next-line no-shadow
      (startDate, yup) =>
        startDate && yup.min(startDate, 'End date cannot be before start date')
    ),
    description: yup.string().required('Please provide a description'),
    zoomSessionID: yup
      .string()
      .required('Please provide webinar ID')
      .matches(/^\d{9,12}$/, 'Webinar ID must be a 9 to 12 digit number'),
    registrationOpenHours: yup
      .number()
      .min(1, 'Registration open hours must be at least 1 hour.')
      .required('Please specify when registration opens (in hours).'),
  })
  .required();

function CreateClassSession({ toggleModal }) {
  const [cohortId, setCohortId] = useState('');
  const [sessionType, setSessionType] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // get cohorts
  const { data: cohortsData } = useFetchDatav2(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohortsData
    ? cohortsData?.data?.cohorts
        .filter((cohort) => cohort.status !== 'ended')
        .map((cohort) => ({
          value: cohort.id,
          label: `${cohort.name} - ${cohort.no_of_students || 0} students`,
        }))
    : [];

  const sessionTypeOptions = [
    {
      label: 'MEETING',
      value: 'MEETING',
    },
    {
      label: 'WEBINAR',
      value: 'WEBINAR',
    },
  ];

  const queryClient = useQueryClient();

  const onCreateClassSessionCreationSuccess = () => {
    toast.success('Class session successfully created!');
    toggleModal();
  };

  const onCreateClassSessionCreationFailure = (err) => {
    toast.error(
      `Class session creation failed! ${err?.response?.data?.error}.`
    );
  };

  const { mutate, isLoading: isSubmitting } = useMutation(
    (data) => {
      return axiosInstancev2.post('/class-sessions', data);
    },
    {
      onSuccess: () => {
        onCreateClassSessionCreationSuccess();
        queryClient.invalidateQueries(['class-sessions']);
        queryClient.invalidateQueries(['class-sessions', cohortId]);
      },
      onError: onCreateClassSessionCreationFailure,
    }
  );

  const onSubmit = async (requestData) => {
    mutate({
      ...requestData,
      cohortId,
      zoomSessionType: sessionType,
      startDate: new Date(requestData.startDate).toISOString(),
      endDate: new Date(requestData.endDate).toISOString(),
    });
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-2 gap-4 mt-2">
          <div className="flex flex-col col-span-2 mb-2">
            <label htmlFor="cohort" className="text-base">
              Select Scholarship Cohort
            </label>
            <Select
              placeholder="Select a cohort"
              isClearable
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={cohortOptions}
              onChange={(event) => {
                setCohortId(event?.value);
              }}
              value={cohortOptions.filter(
                (option) => option.value === cohortId
              )}
            />
            {!cohortId && (
              <span className="text-red-600  mt-2">Please select a cohort</span>
            )}
          </div>
          <div className="flex flex-col col-span-2 mb-2">
            <label
              htmlFor="zoomSessionType"
              className="text-mine-shaft-500 mb-1 pl-1"
            >
              Select Session Type
            </label>
            <Select
              placeholder="Select Session Type"
              isClearable
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={sessionTypeOptions}
              onChange={(event) => {
                setSessionType(event?.value);
              }}
              value={sessionTypeOptions.filter(
                (option) => option.value === sessionType
              )}
            />
            {!sessionType && (
              <span className="text-red-600  mt-2">
                Please select session type
              </span>
            )}
          </div>
          <div className="flex flex-col mb-2">
            <label
              htmlFor="startDate"
              className=" text-mine-shaft-500 mb-1 pl-1"
            >
              Session Start Date
            </label>
            <input
              id="startDate"
              {...register('startDate')}
              className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
                errors.startDate ? 'border-red-600 focus:outline-red-600' : ''
              }`}
              type="datetime-local"
              placeholder="Start Date"
              min={new Date().toISOString().split('T')[0]}
            />
            {errors.startDate && (
              <span className="text-red-600  mt-1">
                {errors.startDate?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col mb-2">
            <label htmlFor="endDate" className=" text-mine-shaft-500 mb-1 pl-1">
              Session End Date
            </label>
            <input
              id="endDate"
              {...register('endDate')}
              className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
                errors.endDate ? 'border-red-600 focus:outline-red-600' : ''
              }`}
              type="datetime-local"
              placeholder="End Date"
              min={new Date().toISOString()}
            />
            {errors.endDate && (
              <span className="text-red-600  mt-1">
                {errors.endDate?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col col-span-2 mb-2">
            <label htmlFor="title" className=" text-mine-shaft-500 mb-1 pl-1">
              Session Title
            </label>
            <input
              id="title"
              {...register('title')}
              className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
                errors.title ? 'border-red-600 focus:outline-red-600' : ''
              }`}
              type="text"
              placeholder="Session Title"
            />
            {errors.title && (
              <span className="text-red-600  mt-1">
                {errors.title?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col col-span-2 mb-2">
            <label
              htmlFor="zoomSessionID"
              className="text-mine-shaft-500 mb-1 pl-1"
            >
              Session ID {sessionType && `(${sessionType} ID)`}
            </label>
            <input
              id="zoomSessionID"
              {...register('zoomSessionID')}
              className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
                errors.zoomSessionID
                  ? 'border-red-600 focus:outline-red-600'
                  : ''
              }`}
              type="text"
              placeholder="Example: 95204914252"
            />
            {errors.zoomSessionID && (
              <span className="text-red-600  mt-1">
                {errors.zoomSessionID?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col col-span-2 mb-2">
            <label
              htmlFor="registrationOpenHours"
              className=" text-mine-shaft-500 mb-1 pl-1"
            >
              Registration Opens: X Hours Before Class Session Starts
            </label>
            <input
              id="registrationOpenHours"
              {...register('registrationOpenHours')}
              className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
                errors.registrationOpenHours
                  ? 'border-red-600 focus:outline-red-600'
                  : ''
              }`}
              type="number"
              min={1}
              step={1}
              placeholder="Registration opens (e.g., 24 hours before session start)"
            />
            {errors.registrationOpenHours && (
              <span className="text-red-600  mt-1">
                {errors.registrationOpenHours?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col col-span-2 mb-2">
            <label
              htmlFor="description"
              className=" text-mine-shaft-500 mb-1 pl-1"
            >
              Session Description
            </label>
            <textarea
              rows={4}
              id="description"
              {...register('description')}
              className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
                errors.description ? 'border-red-600 focus:outline-red-600' : ''
              }`}
              type="text"
              placeholder="Session Description"
            />
            {errors.description && (
              <span className="text-red-600  mt-1">
                {errors.description?.message}
              </span>
            )}
          </div>
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
              disabled={!cohortId || !sessionType}
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Class Session</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateClassSession;
